@import "variable.scss";

html body {
  background: $color-grey;
  color: $color-black1;
  font-family: "Montserrat", sans-serif;
  font-size: calc(14px + (14 - 14) * ((100vw - 300px) / (1600 - 300)));
  line-height: 20px;
  padding: 0;
  margin: 0;

  @media (min-width: 1680px) {
    font-size: calc(16px + (16 - 16) * ((100vw - 300px) / (1680 - 300)));
    line-height: 24px;
  }
  .container {
    max-width: 100% !important;

    @media (min-width: 992px) {
      padding: 0 20px;
    }
  }

  a {
    color: $color-blue;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: $color-blue;
    }
  }
  &.iPhone .left-download-finance {
    margin-bottom: 85px;
  }
}

.grecaptcha-badge {
  display: none !important;
}

body.page-404 {
  background: #fff;
}

/* layout style */
.main-wrap {
  margin-left: 80px;
  padding-top: 60px;
  overflow: hidden;
  display: flex;
  width: calc(100% - 80px);
  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0;
    padding: 80px 0;
    display: block;
  }
}
.left-sidebar-wrapper {
  background: $color-white4;
  box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.16);
  position: fixed;
  left: 80px;
  height: 100vh;
  z-index: 991;
  @media (min-width: 1000px) {
    width: 21%;
    flex: 1 1 21%;
  }
  @media (max-width: 767px) {
    width: 100%;
    left: 0;
    height: calc(100vh - 130px);
    top: 64px;
    overflow: scroll;
    z-index: 990;
  }
  .mobile-title {
    display: none;
    @media (max-width: 767px) {
      display: block;
      background: #CCE0FF;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      padding: 14px 15px;
      z-index: 9991;
      height: 50px;
      position: fixed;
      width: 100%;
      top: 64px;
      left: 0;
      + .inner-filter-wrap  {
        margin-top: 55px;
      }
    }
  }
  .mobile-btn-wrapper {
    display: none;
    position: fixed;
    bottom: 0;
    background: #fff;
    width: 100%;
    padding: .7rem 1rem;
    box-shadow: 0px -1px 2px 0px #00000029;
    @media (max-width: 767px) {
      display: block;
    }
    .btn {
      height: 40px;
      &:nth-child(2) {
        margin-left: .5rem;
      }
    }
  }
}
.right-sidebar-wrapper {
  background: $color-white;
  box-shadow: -1px 0px 4px 0px rgba(0, 0, 0, 0.16);
  position: fixed;
  right: 0;
  height: 100vh;
  z-index: 990;
  @media (min-width: 1000px) {
    width: 22.25%;
    flex: 1 1 22.25%;
  }
  @media (max-width: 767px) {
    // display: none;
    .mobile-col-c & {
      display: block;
      position: relative;
      height: fit-content;
      margin-bottom: 2rem;
    }
  }
  .wrap-small-card {
    padding: 1rem .7rem 0;
  }
}
.middle-wrapper {
  margin: auto 22.25% auto 21%;

  @media (min-width: 1000px) {
    padding: 1em 2.25em;
    width: 52%;
    flex: 1 1 52%;
    // height: calc(100vh - 60px);
    // overflow-y: scroll;
  }

  @media (min-width: 2000px) {
    margin: auto 23.25% auto 22%;
    padding: 1em 3.5em;
  }
  @media (min-width: 2350px) {
    padding: 1em 4.5em;
  }
  @media (max-width: 767px) {
    margin: auto;
  }
  .mobile-filter-btn {
    display: none;
    position: fixed;
    right: 15px;
    bottom: 80px;
    @media (max-width: 767px) {
      display: block;
    }
  }
}
// floating button
.floating-menu-options {
  top: 25%;
  // bottom: 55%;
  position: fixed;
  right: 15%;
    .p-speeddial {
      .p-button {
        border-radius: 100%;
        background: #fff;
        width: 44px;
        height: 44px;
        border: 4px solid #fff;
        box-shadow: 0px 4px 4px 0px #00000029;
        &.p-button-icon-only {
          background: #001433;
        }
        img {
          width: 24px;
        }
      }
      .p-speeddial-list {
        padding-left: 0;
        margin-bottom: 0;
      }
    }
}

.p-element {
  @media (max-width: 991px) {
    .p-dialog[pn_id_5]:not(.p-dialog-maximized) {
      width: 95vw !important;
    }
    .form-wrapper {
      width: inherit !important;
    }
  }
}
.mobile-filter-btn {
  background: #F9F9F9;
  border: 1px solid #000;
  box-shadow: 0px 2px 6px 0px #00000026;
  border-radius: 4px;
  z-index: 1501;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  padding: .6rem;
  cursor: pointer;
}

img {
  width: auto;
  min-width: auto;
  height: auto;
  min-height: auto;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
}

.btn {
  &.btn-outline-light {
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;
    min-width: 115px;
    height: 32px;
    padding: 0.5em 1.4em;
  }

  &.btn-blue-bg {
    background: $color-blue3;
    border-color: $color-blue3;
    color: $color-blue4;

    &:hover {
      background: rgba(0, 102, 255, 0.8);
      border-color: rgba(0, 102, 255, 0.8);
      color: $color-white;
    }
  }

  &.btn-white-bg {
    background: $color-white;
    border-color: $color-white;
    color: $color-lightblack;
  }

  &.btn-grey-bg {
    background: $color-grey;
    border: 1px solid $color-grey3;
    color: $color-black;
    font-size: 14px;
    line-height: 18px;
    padding: .5em 1em;
    text-align: center;
    &:hover {
      background: $color-grey3;
    }
  }

  &.btn-dark-blue-bg {
    background: $color-blue;
    color: $color-white;
    font-size: 16px;
    line-height: 18px;
    height: 40px;
    text-align: center;
    &:hover {
      background: $color-blue2;
      color: $color-white;
    }
  }

  &.btn-grey2-bg {
    color: $color-black1;
    font-size: 14px;
    border-color: $color-grey3;
    background: $color-grey3;
    text-align: center;
    height: 40px;
    &:hover {
      background: $color-grey;
    }
  }

  &.btn-black-bg {
    background: $color-black1;
    border-color: $color-black1;
    color: $color-white;
    min-width: 100px;
  }

  &.btn-darkblue-bg {
    background: $color-blue4;
    border-color: $color-blue4;
    color: $color-white;

    &:hover {
      background: $color-blue1;
      border-color: $color-blue1;
      color: $color-white;
    }
  }

  &.btn-ghost {
    border: 1px solid $color-blue4;
    box-sizing: border-box;
    border-radius: 4px;
    &:hover {
      border-color: $color-blue4;
    }
  }

  &.btn-link:focus,
  &:focus {
    outline: none;
    box-shadow: none;
    text-decoration: none;
  }

  &.btn-outline-light:disabled,
  &.btn:disabled {
    background: rgba(0, 0, 0, 0.1);
    border-color: rgba(0, 0, 0, 0.1);
    color: $color-black1;
    opacity: 0.8;
  }
}

.link-btn {
  color: $color-blue;
  background: $color-white;
  font-size: 12px;
  line-height: 15px;
  padding: 0.5em 0.8em;
  cursor: pointer;

  i {
    vertical-align: middle;
    font-size: 0.9rem;
  }
}

.t-body .user-avatar {
  width: 30px;
  height: 30px;
}

.avatar-default {
  width: 100% !important;
}

.card-wrapper {
  display: block;
  margin: auto;

  .p-card {
    padding: 0 1.5rem 2.5rem;
  }
}

.card-bg-white {
  background: $color-white;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
}

.floating-button {
  position: fixed;
  right: 3px;
  bottom: 50px;
  z-index: 30;

  &.back-button {
    top: calc(100vh - 80%);
    bottom: unset;
    right: unset;
    left: 3px;
  }

  &.add-icon {
    .p-button-icon {
      &::after {
        content: "\f067";
        font-size: 12px;
      }
    }
  }

  .label-text {
    display: none;
    position: fixed;
    right: 55px;
    bottom: 50px;
    background: $color-blue;
    color: $color-white;
    font-size: 14px;
    padding: 0.3rem 1rem;
    border-radius: 18px;
  }

  .p-button.p-button-rounded {
    padding: 1.5rem;

    &:hover {
      + .label-text {
        display: block;
      }
    }
  }
}

.btn-wrap {
  button:first-child {
    margin-right: 1rem;
  }
}

form {
  .alert {
    border: none;
    background-color: transparent;
    color: $color-red;
    padding: 0;
    margin-bottom: 0;
  }
}

.page-title {
  font-size: 1.8rem;
}

.data-table {
  margin: 2rem 0;

  .p-datatable {
    background: $color-white;
    border: 1px solid $color-grey1;
    height: calc(100vh - 180px);

    .p-datatable-wrapper {
      height: calc(100vh - 250px);
      overflow-y: scroll;
    }

    .p-paginator-bottom {
      border-bottom: none;
    }
  }
}

.wrap-breadcrumb {
  background: $color-white;
  border: 1px solid $color-grey2;
  border-radius: 2px;
  margin-bottom: 1rem;
  margin-top: 70px;
}

.p-breadcrumb.p-component {
  padding: 0.5rem 1rem;
  border: 0;
  border-radius: 0;

  li.p-breadcrumb-chevron {
    font-size: 12px;
    margin-top: 2px;
  }

  a {
    font-size: 14px;
  }
}

.card-height {
  height: calc(100vh - 218px);
  margin-top: 1em;

  .p-card {
    height: inherit;
  }

  .rigt-side {
    img {
      height: 150px;
    }
  }

  .info-wrap {
    margin-bottom: 1.5em;
  }

  .wrap-fields {
    height: calc(100vh - 340px);
    overflow-y: scroll;
    margin-bottom: 1em;
    padding-right: 1em;
  }

  .wrap-option-list {
    height: calc(100vh - 368px);
    overflow-y: scroll;
    margin-bottom: 1em;
    padding-right: 1em;
  }

  .wrap-desc {
    height: calc(100vh - 473px);
    overflow-y: scroll;
  }
}

.admin-list-wrap {
  margin-bottom: 0.5rem;
  border-bottom: 1px solid $color-grey1;
  padding-bottom: 0.5em;

  .p-button.p-button-danger {
    margin-left: 0.7em;
    flex: 0 0 37px;
    height: 40px;
  }
}

input.form-control {
  border-radius: 2px;
  border-color: $color-grey5;
  box-sizing: border-box;
  padding: 4px 12px;

  &:focus {
    outline: 0;
    box-shadow: none;
    border-color: $color-blue;
  }
}

textarea,
input {
  &::-webkit-input-placeholder {
    color: #3f3f3f;
    font-size: 14px;
    font-family: $font-montserrat;
    line-height: 22px;
  }

  &::-moz-placeholder {
    color: #3f3f3f;
    font-size: 14px;
    font-family: $font-montserrat;
    line-height: 22px;
  }

  &:-ms-input-placeholder {
    color: #3f3f3f;
    font-size: 14px;
    font-family: $font-montserrat;
    line-height: 22px;
  }

  &:-moz-placeholder {
    color: #3f3f3f;
    font-size: 14px;
    font-family: $font-montserrat;
    line-height: 22px;
  }
}

.checkbox {
  &__label {
    color: $color-black;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 25px;
    margin-bottom: .5rem;
    cursor: pointer;
    font-size: 14px;
    line-height: 22px;
    font-family: $font-montserrat;
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
  }

  &__mark {
    border: 2px solid $color-grey3;
    background: $color-white;
    position: absolute;
    top: 2px;
    left: 0;
    width: 16px;
    height: 16px;
    // background-color: transparent;
    border-radius: 2px;
    transition: background-color 0.3s;

    &:after {
      position: absolute;
      top: 0;
      left: 3px;
      display: none;
      width: 6px;
      height: 10px;
      content: "";
      border: solid $color-white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }

  &__count {
    opacity: 0.85;
    padding-left: 0.3em;
  }
}

.checkbox__input:checked ~ .checkbox__mark {
  background-color: $color-blue;
  border-color: $color-blue;

  &:after {
    display: flex;
  }
}

.heading-section {
  background: $color-white;
  margin-bottom: 2.15em;
  position: relative;
  padding-bottom: 2em;
  min-height: 130px;

  @media (max-width: 767px) {
    padding-bottom: 0;
  }

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    padding: 1.5em 0;
  }

  .title-wrap {
    .download {
      margin: -0.7em 0 0 2em;
    }
  }
}

.title-wrap {
  .edit {
    margin-right: 2.15em;
  }
}

.wrap-competitors {
  margin-top: 2.8em;

  .title-section {
    margin-bottom: 1.25em;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }
}

.space-mt-mb {
  margin-top: 30px;
  margin-bottom: 2em;
}

.mr-tp-bt {
  margin-top: 116px;
  margin-bottom: 2em;

  @media screen and (min-width: 992px) {
    margin-bottom: 80px;
  }
}

.bg-white {
  background: $color-white;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin-bottom: 1em;
  padding: 1rem;
  @media (max-width: 767px) {
    margin-bottom: 1rem;
    border-radius: 0;
    box-shadow:0;
  }
  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    padding: 0;
    margin-bottom: 1.75em;
  }
}

.bg-grey {
  background: $color-white6;
}

.bg-blue{
  background: $color-blue3;
}

.wrap-carousel {
  position: relative;
}

.p-carousel.p-carousel-horizontal {
  .p-carousel-content {
    .p-carousel-prev,
    .p-carousel-next {
      background: $color-black;
      border-radius: 100%;
      box-shadow: 1px 3px 2px 0px #00000047;
      color: $color-white;
      position: absolute;
      top: 0;
      bottom: 0;
      right: -15px;
      margin: 0;
      z-index: 8;
      width: 28px;
      height: 28px;
      @media (max-width: 767px) {
        right: 0;
        // top: 50%;
      }
      @media (min-width: 2000px) {
        right: -18px;
      }
      &:focus {
        outline: 0;
        border: 0;
        box-shadow: none;
      }

      &:enabled:hover {
        color: $color-white;
      }
    }

    .p-carousel-prev {
      left: -15px;
      right: unset;
      @media (max-width: 767px) {
        left: 0;
      }
      @media (min-width: 2000px) {
        left: -22px;
      }
    }

    .pi {
      font-size: 1.5rem;
    }
  }
  .p-carousel-indicators {
    display: none;
  }
  .p-carousel-item {
    flex: 1 0 48% !important;
    margin: 0 0.75em;
    @media (max-width: 767px) {
      align-content: center;
      // flex: 1 0 96% !important;
      margin: 0 10px 0 0;
      width: 100%;
    }
  }
}

.down {
  color: $color-red3 !important;
}

.vname {
  font-size: 20px;
  // white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

/* search result style */
.search-input {
  position: relative;

  .search-list-wrap {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 40px;
    width: 100%;
  }

  .search-list {
    border: 1px solid #d9d9d9;
    border-top: 0;
    background: #f1f1f1;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: left;

    &:empty {
      display: none;
    }

    .search-item {
      padding: 0.5em 1em;
      cursor: pointer;

      &:hover {
        background: #fff;
      }

      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
}
.p-component-overlay.p-sidebar-mask {
  background: none;
}

.nav-link {
  cursor: pointer !important;
}

.form-wrap, .select-list {
  select.form-control {
    appearance: auto;
  }
}

@media (max-width: 767px) {
  .single-line {
    text-size-adjust: auto;
    white-space: nowrap;
    font-size: 3vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


.otp-error-state {
  border: 1px solid #DC0619 !important;
}
.otp-success-state {
  border: 1px solid #1C9511 !important;
}
.error-msg {
  color: #DC0619
}

.sectionname {
  position: relative;
  color: $color-grey7;
  font-size: 14px;
  text-align: center;
  &:after {
    content: "";
    border: 1px dashed $color-grey9;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    margin: auto;
  }
  span {
    background: $color-white;
    padding: .2em .5em;
    position: relative;
    z-index: 2;
    @media (max-width: 767px) {
      background-color: #f3f3f3;
    }
  }
}

.sec-title {
  color: $color-black5;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: .5em;
  @media (min-width:1680px) {
    font-size: 18px !important;
    line-height: 22px !important;
  }
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
}

.wrap-free-graph {
  margin: 2em 0;
}

@media (max-width: 767px) {
  .wrapgraph {
    .title-wrap {
      .download-btn {
        margin-right: .5rem;
      }
    }
  }
  .table-wrap {
    overflow-x: auto;
  }
}
