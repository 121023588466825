// colors var
$color-black: #000;
$color-black1: #111;
$color-black2: #444;
$color-black3: #222222;
$color-black4: #232323;
$color-black5: #333333;
$color-black6: #332000;
$color-black7: #262626;
$color-lightblack: #525252;
$color-lightblack1: #545454;
$color-white: #fff;
$color-white1: #f1f1f1;
$color-white2: #f2f2f2;
$color-white3: #fbfbfb;
$color-white4: #f9f9f9;
$color-white5: #f6f6f6;
$color-white6: #f3f3f3;
$color-blue: #0066ff;
$color-blue1: #002966;
$color-blue2:  #0052CC;
$color-blue3: #CCE0FF;
$color-blue4: #001433;
$color-blue5: #66A3FF;
$color-blue6: #E8F2FF;
$color-blue7: #197BFF;
$color-blue8: #003D99;
$color-lightblue: #E6F0FF;
$color-grey: #F3F3F3;
$color-grey1: #ddd;
$color-grey2: #eee;
$color-grey3: #DEDEDE;
$color-grey4: #e6e6e6;
$color-grey5: #C7C7C7;
$color-grey6: #3F3F3F;
$color-grey7: #A7A7A7;
$color-grey8: #656565;
$color-grey9: #858585;
$color-grey10: #332B00;
$color-red: #d13438;
$color-red1: #f00e0e;
$color-red2: #dd2e44;
$color-red3: #C00B0B;
$color-red4: #FEE7E7;
$color-red5: #FDF3F2;
$color-darkred: #900808;
$color-darkred1: #DC2804;
$color-green: #5AA80B;
$color-green1: #70D20E;
$color-green2: #437E08;
$color-darkblue: #031d35;
$color-navyblue: #001433;
$color-purple: #6f02c5;
$color-purple1: #EADAFF;
$color-purple2: #9747FF;
$color-purple3: #1E0E33;
$color-purple4: #7939CC;
$color-purple5: #F5EDFF;
$color-yellow: #FFE666;
$color-yellow1:#FFFBE6;
$color-yellow2: #FFD899;
$color-orange: #FF9E00;
$color-orange1: #FFF5E6;
$color-orange2: #663F00;
$color-orange3: #FFECCC;
$color-orange4: #CC7E00;


$font-roboto: "Roboto", sans-serif;
$font-montserrat-alternates: "Montserrat Alternates", sans-serif;
$font-montserrat: "Montserrat", sans-serif;
